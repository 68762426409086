<template>
    <div class='grid crud-demo'>
        <div class='col-12'>
            <div class='card'>
                <Toast />
                <Toolbar class='mb-4'>
                    <template v-slot:end>
                        <div class='my-2'>
                            <Dropdown :filter='true'
                                      :options='banks' id='banco' v-model='transaction.bankId' optionLabel='label'
                                      placeholder='Selecione um banco'>
                                <template #value='slotProps'>
                                    <div v-if='slotProps.value && slotProps.value.value'>
                                        <span>{{ slotProps.value.label }}</span>
                                    </div>
                                    <span v-else>
									{{ slotProps.placeholder }}
								</span>
                                </template>
                            </Dropdown>
                        </div>

                    </template>
                </Toolbar>

                <DataTable ref='dt' :value='transactions' v-model:selection='selectedTransactions' dataKey='id'
                           :paginator='true' :rows='10' :filters='filters'
                           paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
                           :rowsPerPageOptions='[5,10,25]'
                           currentPageReportTemplate='Mostrando {first} ~ {last} total {totalRecords} despesas'
                           responsiveLayout='scroll'>
                    <template #header>
                        <div class='flex flex-column md:flex-row md:justify-content-between md:align-items-center'>
                            <h5 class='m-0'>Transaçōes</h5>
                            <span class='block mt-2 md:mt-0 p-input-icon-left'>
                                <i class='pi pi-search' />
                                <InputText v-model="filters['global'].value" placeholder='Pesquisar...' />
                            </span>
                        </div>
                    </template>

                    <Column selectionMode='multiple' headerStyle='width: 3rem'></Column>
                    <Column headerStyle='width: 20rem' field='name' header='Nome' :sortable='true'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Nome</span>
                            {{ slotProps.data.name }}
                        </template>
                    </Column>
                    <Column headerStyle='width: 20rem' field='amount' header='Valor' :sortable='true'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Nome</span>
                            {{ formatCurrency(slotProps.data.amount) }}
                        </template>
                    </Column>
                    <Column headerStyle='width: 20rem' field='date' header='Data'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Data</span>
                            {{ slotProps.data.date }}
                        </template>
                    </Column>
                    <Column headerStyle='width: 20rem' field='category' header='Categoria'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Categoria</span>
                            {{ slotProps.data.category.name }}
                        </template>
                    </Column>
                    <Column headerStyle='width: 20rem' field='bank' header='Banco'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Banco</span>
                            {{ slotProps.data.bank.name }}
                        </template>
                    </Column>
                    <Column headerStyle='width: 8rem'>
                        <template #body='slotProps'>
                            <Button icon='pi pi-pencil' class='p-button-rounded p-button-success mr-2'
                                    @click='editTransaction(slotProps.data)' />
                            <Button icon='pi pi-trash' class='p-button-rounded p-button-warning'
                                    @click='confirmDeleteTransaction(slotProps.data)' />
                        </template>
                    </Column>
                </DataTable>

                <Dialog v-model:visible='transactionDialog' :style="{width: '450px'}" header='Detalhe da transação'
                        :modal='true' class='p-fluid'>
                    <div class='field'>
                        <label for='name'>Nome*</label>
                        <InputText id='name' disabled v-model.trim='transaction.name' required='true' autofocus
                                   :class="{'p-invalid': v$.transaction.name.$invalid && submitted}" />
                        <small
                            v-if='(v$.transaction.name.$invalid && submitted) || v$.transaction.name.$pending.$response'
                            class='p-error'>{{ v$.transaction.name.required.$message.replace('Value', 'Nome') }}</small>
                    </div>

                    <div class='field'>
                        <label for='amount'>Valor*</label>
                        <InputNumber id='amount' disabled mode='currency' currency='JPY' locale='ja-JP'
                                     v-model.trim='transaction.amount' required='true' autofocus
                                     :class="{'p-invalid': v$.transaction.amount.$invalid && submitted}" />
                        <small
                            v-if='(v$.transaction.amount.$invalid && submitted) || v$.transaction.amount.$pending.$response'
                            class='p-error'>{{ v$.transaction.amount.required.$message.replace('Value', 'Valor')
                            }}</small>
                    </div>

                    <div class='field'>
                        <label for='date'>Data*</label>
                        <Calendar dateFormat='yy-mm-dd' disabled id='date' v-model.trim='transaction.date'
                                  required='true' autofocus
                                  :class="{'p-invalid': v$.transaction.date.$invalid && submitted}" />
                        <small
                            v-if='(v$.transaction.date.$invalid && submitted) || v$.transaction.date.$pending.$response'
                            class='p-error'>{{ v$.transaction.date.required.$message.replace('Value', 'Data') }}</small>
                    </div>


                    <div class='field'>
                        <label for='description'>Descrição</label>
                        <Textarea :maxlength='200' disabled id='description' v-model.trim='transaction.description'
                                  autofocus />
                    </div>

                    <div class='field'>
                        <label for='category'>Categoria*</label>
                        <Dropdown :filter='true' disabled
                                  :class="{'p-invalid': v$.transaction.categoryId.$invalid && submitted}"
                                  :options='categories' id='category' v-model='transaction.categoryId'
                                  optionLabel='label'
                                  placeholder='Seleciona uma categoria'>
                            <template #value='slotProps'>
                                <div v-if='slotProps.value && slotProps.value.value'>
                                    <span>{{ slotProps.value.label }}</span>
                                </div>
                                <span v-else>
									{{ slotProps.placeholder }}
								</span>
                            </template>
                        </Dropdown>
                        <small
                            v-if='(v$.transaction.categoryId.$invalid && submitted) || v$.transaction.categoryId.$pending.$response'
                            class='p-error'>{{ v$.transaction.categoryId.required.$message.replace('Value', 'Categoria')
                            }}</small>
                    </div>

                    <div class='field-checkbox'>
                        <Checkbox id='isToMoveAccount' v-model='transaction.isToMoveAccount' :binary='true' />
                        <label for='isToMoveAccount'>Descontar do saldo da conta do banco ?</label>
                    </div>

                    <template #footer>
                        <Button label='Cancelar' icon='pi pi-times' class='p-button-text' @click='hideDialog' />
                        <Button label='Salvar' icon='pi pi-check' class='p-button-text'
                                @click='saveTransaction(!v$.$invalid)' />
                    </template>
                </Dialog>

            </div>
        </div>
    </div>

</template>

<script>
import { FilterMatchMode } from 'primevue/api';
import TransactionService from '@/services/transaction.service';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import BankService from '@/services/bank.service';
import CategoryService from '@/services/category.service';
import moment from 'moment';

export default {
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            transactions: null,
            transactionDialog: false,
            deleteTransactionDialog: false,
            deleteTransactionsDialog: false,
            transaction: {},
            selectedTransactions: null,
            filters: {},
            banks: {},
            categories: {},
            submitted: false,
        };
    },
    validations: {
        transaction: {
            name: {
                required,
            },
            amount: {
                required,
            },
            date: {
                required,
            },
            categoryId: {
                required,
            },
            bankId: {
                required,
            },
        },
    },
    transactionService: null,
    created() {
        this.initFilters();
    },
    mounted() {
        this.fetchTransactions();
        this.fetchApiForSelectBox();
    },
    methods: {
        formatCurrency(value) {
            //TODO use database to set this.
            if (value) return value.toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' });
        },
        openNew() {
            this.transaction = {};
            this.submitted = false;
            this.transactionDialog = true;
        },
        hideDialog() {
            this.transactionDialog = false;
            this.submitted = false;
        },
        async saveTransaction(isFormValid) {
            if ((!isFormValid && this.transaction.isToMoveAccount)) {
                this.submitted = true;
                return;
            }
            this.transaction.categoryId = this.transaction.categoryId.value;
            this.transaction.bankId = this.transaction.isToMoveAccount ? this.transaction.bankId.value : null;
            this.transaction.date = moment(this.transaction.date).format('YYYY-MM-DD');
            if (this.transaction.name.trim()) {
                if (this.transaction.id) {
                    try {
                        let resp = await TransactionService.update(this.transaction);
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Success',
                            detail: resp.data.message,
                            life: 3000,
                        });
                    } catch (e) {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: e.response.data.message,
                            life: 5000,
                        });
                    }
                } else {
                    try {
                        let resp = await TransactionService.create(this.transaction);
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Success',
                            detail: resp.data.message,
                            life: 3000,
                        });
                    } catch (e) {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: e.response.data.message,
                            life: 5000,
                        });
                    }
                }
            }
            await this.fetchTransactions();
            this.transactionDialog = false;
            this.transaction = {};
        },
        editTransaction(transaction) {
            this.transaction = { ...transaction };
            //set selected value on Dropdown
            this.transaction.categoryId = this.categories.filter((item) => item.value === this.transaction.category.id)[0];
            this.transaction.bankId = this.banks.filter((item) => item.value === this.transaction.bank.id)[0];

            this.transactionDialog = true;
        },
        confirmDeleteTransaction(transaction) {
            this.transaction = transaction;
            this.deleteTransactionDialog = true;
        },
        async deleteTransaction() {
            try {
                let resp = await TransactionService.delete(this.transaction.id);
                this.$toast.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: resp.data.message,
                    life: 3000,
                });
            } catch (e) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: e.response.data.message,
                    life: 5000,
                });
            }
            await this.fetchTransactions();
            this.deleteTransactionDialog = false;
            this.transaction = {};
        },
        confirmDeleteSelected() {
            this.deleteTransactionsDialog = true;
        },
        async deleteSelectedTransactions() {
            try {
                let resp = await TransactionService.deleteMany(this.selectedTransactions);
                this.$toast.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: resp.data.message,
                    life: 3000,
                });
            } catch (e) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: e.response.data.message,
                    life: 5000,
                });
            }
            await this.fetchTransactions();
            this.deleteTransactionsDialog = false;
            this.selectedTransactions = null;
        },
        initFilters() {
            this.filters = {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
        async fetchTransactions() {
            let resp = await TransactionService.find(1);
            this.transactions = resp.data;
            console.log(111, resp.data);
        },
        async fetchApiForSelectBox() {
            let bankResp = await BankService.find();
            if (bankResp.data) {
                this.banks = bankResp.data.map(bank => {
                    return { label: bank.name, value: bank.id };
                });
            }

            let catResp = await CategoryService.find();
            if (catResp.data) {
                this.categories = catResp.data.filter(item => item.transaction).map(cat => {
                    return { label: cat.name, value: cat.id };
                });
            }
        },
    },
};
</script>

<style scoped lang='scss'>

</style>
